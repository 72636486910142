import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"

class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo
          title="Products"
          keywords={[
            "blog",
            "javascript",
            "typescript",
            "contractor",
            "software",
            "engineer",
            "react",
            "native",
            "react-native",
            "swift",
            "ios",
            "android",
            "mobile",
            "app",
          ]}
        />
        <h1>
          Hi
        </h1>
        <p>
          My name is Jakub Adamczyk, but you can just call me Kuba<br/>(pronounced{" "}<span style={{ fontStyle: "italic" }}>/koobah/</span>{" "}).
        </p>
        <p>
          I'm a software engineer based in Europe. My main area of expertise is making mobile applications. My technology of choice is usually React Native, but I also work with Native iOS technologies like SwiftUI, UIKit and AppKit.
        </p>
        <p>
          When I'm not in front of the computer, you can find me at the gym, running in the park, hiking on a trail, cooking in the kitchen, walking my dog or chillin' on a sofa playing games.
        </p>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
